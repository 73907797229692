import { ReactComponent as GearIcon } from "assets/svg/gear.svg";
import { Flex, FlexColumn, FlexRow } from "Components/Flex";
import { SpinnerRing } from "Components/LoadingScreen/SpinnerRing";
import { collection, doc, getFirestore } from "firebase/firestore";
import { Field, FieldArray, FieldProps, Form, Formik } from "formik";
import { useMobileMediaQuery } from "hooks";
import { useConcertsAi } from "hooks/useConcertsAi";
import { DateTime } from "luxon";
import { Button } from "melodies-source/Button";
import { Checkbox } from "melodies-source/Selectable";
import { SvgHelpAlt } from "melodies-source/Svgs/HelpAlt";
import { Body1, Body2, Caption, H3, H4 } from "melodies-source/Text";
import { HTMLTextearea } from "melodies-source/Textarea";
import { ReactElement, useState } from "react";
import styled from "styled-components";
import { pluralize } from "Utils/pluralize";
import { REQUIRED_MSG } from "Utils/yupStrictPassword";
import * as yup from "yup";
import { ConcertCard } from "./ConcertCard";
import { ConcertDetails } from "./types";

enum FormStatus {
  NoShowsFound,
}

const PROMPT_SCHEMA = yup.object().shape({
  prompt: yup.string().trim().required(REQUIRED_MSG),
});

const SELECTION_SCHEMA = yup.object().shape({
  selection: yup.array().min(1, "Shows are required"),
});

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 0;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 320px minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr);
  column-gap: 30px;
  flex-grow: 1;
  min-height: 0;

  ${(p) => p.theme.mediaQueries.mobile} {
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: min-content minmax(0, 1fr);
    row-gap: 20px;
  }
`;

const Footer = styled.div`
  display: flex;
  gap: 20px;
  justify-content: end;
  margin-top: 30px;

  ${(p) => p.theme.mediaQueries.mobile} {
    flex-direction: column-reverse;
  }
`;

const Column = styled(FlexColumn)`
  min-height: 0;
`;

const List = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  row-gap: 10px;
  min-height: 0;
  flex-grow: 1;
  overflow: auto;
`;

export interface BulkFormProps {
  controls: ReactElement;
  upcoming: { date: DateTime }[];
  onCancel: () => void;
  onSubmit: (concerts: ConcertDetails<Date>[]) => void;
}

export const BulkForm = ({
  controls,
  upcoming,
  onCancel,
  onSubmit,
}: BulkFormProps) => {
  const [options, setOptions] = useState<ConcertDetails[]>([]);
  const { analyze } = useConcertsAi();
  const isMobile = useMobileMediaQuery();

  const findOverlappingShow = (option: ConcertDetails) =>
    upcoming.find((show) => show.date.toISODate() === option.date.toISODate());

  const overlapped = options.filter((o) => findOverlappingShow(o));
  const Title = isMobile ? H4 : H3;
  const Text = isMobile ? Body2 : Body1;

  return options.length ? (
    <Formik<{ selection: string[] }>
      key="options"
      validationSchema={SELECTION_SCHEMA}
      initialValues={{
        selection: options
          .filter((o) => !findOverlappingShow(o))
          .map((o) => o.id),
      }}
      onSubmit={(values) => {
        onSubmit(
          options
            .filter((o) => values.selection.includes(o.id))
            .map((o) => ({ ...o, date: o.date.toJSDate() })),
        );
      }}
    >
      {({ values, touched, errors }) => (
        <StyledForm>
          <Grid>
            <Column>
              <Text>
                We found new{" "}
                {pluralize(options.length - overlapped.length, "show")}
                {!!overlapped.length && (
                  <>
                    , and {pluralize(overlapped.length, "show")} that already
                    exist on this account
                  </>
                )}
                . Please review for accuracy before importing new shows.
              </Text>
            </Column>
            <Column>
              <FieldArray name="selection">
                {({ push, remove }) => (
                  <List>
                    {options.map((option) => {
                      const overlappingShow = findOverlappingShow(option);

                      return (
                        <Flex
                          key={option.id}
                          gap="16px"
                          style={{
                            minWidth: 0,
                          }}
                        >
                          <FlexRow yCenter style={{ width: 16 }}>
                            {!overlappingShow && (
                              <Checkbox
                                value={values.selection.includes(option.id)}
                                onChange={(checked) =>
                                  checked
                                    ? push(option.id)
                                    : remove(
                                        values.selection.indexOf(option.id),
                                      )
                                }
                              />
                            )}
                          </FlexRow>
                          <ConcertCard
                            concert={option}
                            isNew={!overlappingShow}
                            isSelected={values.selection.includes(option.id)}
                          />
                        </Flex>
                      );
                    })}
                  </List>
                )}
              </FieldArray>
              {touched.selection && errors.selection && (
                <Caption hasError style={{ marginTop: "4px" }}>
                  {errors.selection.toString()}
                </Caption>
              )}
            </Column>
          </Grid>
          <Footer>
            <Button
              type="button"
              variant="secondary"
              onClick={() => setOptions([])}
            >
              Restart
            </Button>
            <Button type="submit">Import New Shows</Button>
          </Footer>
        </StyledForm>
      )}
    </Formik>
  ) : (
    <Formik<{ prompt: string }>
      key="prompt"
      initialValues={{ prompt: "" }}
      validationSchema={PROMPT_SCHEMA}
      onSubmit={async (values, form) => {
        const results = await analyze(values.prompt);

        if (results.length) {
          setOptions(
            results.map(
              (result) =>
                ({
                  id: doc(collection(getFirestore(), "ids")).id,
                  address:
                    result?.address ||
                    [result.city, result.state, result.country]
                      .filter(Boolean)
                      .join(", "),
                  addressType: "address",
                  coords: result.coordinates,
                  timeZone: result.timeZone,
                  venue: result.venue,
                  source: "manual",
                  locationAddedManually: true,
                  date: DateTime.fromISO(result.date, {
                    zone: result.timeZone,
                  }),
                  deletedAt: null,
                } as ConcertDetails),
            ),
          );
        } else {
          form.setStatus(FormStatus.NoShowsFound);
        }
      }}
    >
      {({ status, handleReset, isSubmitting }) => (
        <StyledForm>
          <Grid>
            <Column>
              {isSubmitting ? (
                <>
                  <Title>Stay on this screen!</Title>
                  <Text style={{ marginTop: 20 }}>
                    Thanks for your patience. Behind the scenes, we are saving
                    you time by using AI to locate, format, and organize all
                    your upcoming shows.
                  </Text>
                </>
              ) : status === FormStatus.NoShowsFound ? (
                <Text>
                  We could not compile a show list based on the information
                  provided. Please check the text that was copied and try again.
                  <br />
                  <br />
                  If available, include the venue, city, date, and time. Please
                  use official venue names for an accurate location.
                </Text>
              ) : (
                controls
              )}
            </Column>
            <Column>
              {isSubmitting ? (
                <SpinnerRing
                  icon={<GearIcon />}
                  message="Analyzing Show Info..."
                />
              ) : status === FormStatus.NoShowsFound ? (
                <FlexColumn xCenter>
                  <SvgHelpAlt width={63} height={63} />
                  <Title>No Shows Found</Title>
                </FlexColumn>
              ) : (
                <>
                  <Field name="prompt">
                    {({ field, meta }: FieldProps) => {
                      const props = {
                        placeholder: "Paste shows here...",
                        hasError: meta.touched && !!meta.error,
                        style: { flexGrow: 1, minHeight: 0 },
                      };

                      return (
                        <>
                          <HTMLTextearea {...props} {...field} />
                          {props.hasError && (
                            <Caption hasError style={{ marginTop: "4px" }}>
                              {meta.error}
                            </Caption>
                          )}
                        </>
                      );
                    }}
                  </Field>
                  {!isMobile && (
                    <Body2 style={{ marginTop: "4px" }}>
                      If available, include the venue, city, date, and time
                      (links and/or buttons will be ignored if they are copied).
                      Please use official venue names for an accurate location.
                      Processing may take time.
                    </Body2>
                  )}
                </>
              )}
            </Column>
          </Grid>
          {!isSubmitting && (
            <Footer>
              <Button type="button" variant="secondary" onClick={onCancel}>
                Cancel
              </Button>
              {status === FormStatus.NoShowsFound ? (
                <Button type="button" onClick={handleReset}>
                  Restart
                </Button>
              ) : (
                <Button type="submit">Analyze</Button>
              )}
            </Footer>
          )}
        </StyledForm>
      )}
    </Formik>
  );
};
