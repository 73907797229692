import SetFanKnockout from "../../../../svg/SetFanKnockout";
import { AppCard } from "./AppCard";
import styled from "styled-components";
import { CenterFlexRow, Font12, Font15, Font16, Font40 } from "../../../styled";
import { PastSurveyCard, CurrentSurveyCard } from "./ArtistSurveyCard";
import { DateTime } from "luxon";
import Arrow from "svg/Arrow";
import { svg } from "../../../../svg";
import { useArtistSurveys } from "hooks/useArtistSurveys";
import { useEffect, useState } from "react";
import { SurveyTypesenseDocument } from "@max/common/setfan";
import { useHistory, useParams } from "react-router-dom";
import { SetFanAppNoData } from "./AppCardNoData";
import { useMobileMediaQuery } from "hooks/useMobileMediaQuery";
import { useTabletMediaQuery } from "hooks/useTabletMediaQuery";
import SetFanInterestModal from "../SetFanInterestModal";
import { useScrollLockContext } from "contexts/ScrollLockContext";

const ArtistSurveys = () => {
  const isMobile = useMobileMediaQuery();
  const isTablet = useTabletMediaQuery();

  const [currentSurvey, setCurrentSurvey] = useState<
    SurveyTypesenseDocument | undefined
  >();
  const [pastSurveys, setPastSurveys] = useState<SurveyTypesenseDocument[]>([]);
  const compact = !!pastSurveys.length;
  const { push } = useHistory();
  const { artistId } = useParams<{ artistId: string }>();

  const { documents: surveys, loading: surveysLoading } = useArtistSurveys({
    resultsPerPage: 4,
    sortBy: "lastResponse:desc",
    filterBy: `artistGroupId:${artistId}`,
    limitHits: 4,
  });

  useEffect(() => {
    if (surveys.length) {
      const mostRecentSurveyResponse = DateTime.fromMillis(
        surveys[0]?.lastResponse ?? 0,
      );

      const currentSurvey =
        surveys.length &&
        Math.abs(mostRecentSurveyResponse.diffNow().as("days")) < 14
          ? surveys[0]
          : undefined;

      setCurrentSurvey(currentSurvey);

      if (currentSurvey) {
        setPastSurveys(surveys.slice(1));
      } else {
        setPastSurveys(surveys);
      }
    }
  }, [surveys]);

  if (surveysLoading || !surveys.length) {
    return <SetFanAppNoData loading={surveysLoading} />;
  }

  return (
    <StyledAppCard
      buttonText="View all Surveys"
      onButtonClick={() => push("reporting/set-fan")}
      footerIcon={
        <StyledFanKnockout height={!isMobile && !isTablet ? 41 : 33} />
      }
      compact={compact}
    >
      <CardColumn>
        <AppCardHeader>Current Survey</AppCardHeader>
        {!currentSurvey ? (
          <NoCurrentSurveysCard />
        ) : (
          <CurrentSurveyCard
            {...{
              ...currentSurvey,
              lastResponse: DateTime.fromMillis(currentSurvey.lastResponse),
            }}
            compact={!!pastSurveys.length && !isTablet && !isMobile}
          />
        )}
      </CardColumn>
      <>
        {!!pastSurveys.length && (
          <CardColumn>
            <AppCardHeader>Past Surveys</AppCardHeader>
            {/* this should not be longer than 3  */}
            {pastSurveys.slice(0, 3).map((survey) => (
              <PastSurveyCard
                key={survey.id}
                surveyId={survey.id}
                surveyName={survey.internalTitle || survey.title}
                lastResponse={DateTime.fromMillis(survey.lastResponse)}
                responseCount={survey.responses}
              />
            ))}
          </CardColumn>
        )}
      </>
    </StyledAppCard>
  );
};

const StyledFanKnockout = styled(SetFanKnockout)`
  height: 41px;

  ${({ theme }) => theme.media.mobile} {
    height: 33px;
  }
  ${({ theme }) => theme.media.tablet} {
    height: 33px;
  }
`;

const NoCurrentSurveysCard = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const { lockScroll, unlockScroll } = useScrollLockContext();

  return (
    <>
      <NoShowsCard>
        <NoSurveysBlendedBG />
        <NoShowsContent>
          <StyledLogo as={svg.ClipboardLogo} />
          <NoCurrentHeader>You have no active surveys.</NoCurrentHeader>
          <NoCurrentBody>
            Create a new survey to help populate your fan profile.
          </NoCurrentBody>
          <CenterFlexRow
            onClick={() => {
              lockScroll();
              setModalOpen(true);
            }}
          >
            <NoCurrentFooter>Create a Survey</NoCurrentFooter>
            <Arrow fillColor="white" />
          </CenterFlexRow>
        </NoShowsContent>
      </NoShowsCard>
      <SetFanInterestModal
        isOpen={modalOpen}
        onClose={() => {
          unlockScroll();
          setModalOpen(false);
        }}
      />
    </>
  );
};

export default ArtistSurveys;

const AppCardHeader = styled(Font40)`
  font-weight: 700;
  margin-bottom: 32px;

  ${({ theme }) => theme.media.tablet} {
    font-size: 30px;
    line-height: 45px;
  }

  ${({ theme }) => theme.media.mobile} {
    margin-bottom: 7px;
  }
`;

const BlendedBG = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  mix-blend-mode: multiply;
  border-radius: 12px;
`;

const CardColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 14px;

  & > div:nth-last-child(n + 2) {
    margin-bottom: 19px;
  }
`;

const NoCurrentHeader = styled(Font15)`
  font-weight: 600;
  margin-bottom: 6px;
`;

const NoCurrentBody = styled(Font12)`
  font-weight: 500;
  text-align: center;
  margin-bottom: 38px;
`;

const NoCurrentFooter = styled(Font16)`
  margin-bottom: 0 5px 0;
  margin-right: 5px;
  cursor: pointer;
`;
const NoShowsCard = styled.div`
  position: relative;
  height: 282px;
  border-radius: 12px;
  display: flex;
`;

const NoShowsContent = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 39px 27px 48px 27px;
`;

const NoSurveysBlendedBG = styled(BlendedBG)`
  background: #3795bd44;
`;

const StyledAppCard = styled(AppCard)`
  background: linear-gradient(44.42deg, #018ce9 3.08%, #01d274 97.4%);
  min-height: 324px;
`;

const StyledLogo = styled.div`
  margin-bottom: 13px;
`;
