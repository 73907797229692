import { useState } from "react";
import styled from "styled-components";
import { SelectionCard } from "../../SelectionCard";
import { FlexRow } from "Components/Flex";
import { Button } from "Components";
import { freeResponse } from "./FreeResponseQuestionModal";
import { imageQuestion } from "./ImageQuestionModal";
import { multipleChoice } from "./MultipleChoiceQuestionModal";
import { ratingQuestion } from "./RatingQuestionModal";
import { image } from "./AddImageModal";
import { text } from "./AddTextModal";
import {
  BaseQuestionModal,
  QuestionModalProps,
  QuestionType,
  QuestionTypeMeta,
} from "./BaseQuestionModal";
import { Body1, H2 } from "melodies-source/Text";
import { useScrollLockContext } from "contexts/ScrollLockContext";
import { Modal } from "melodies-source/Modal";

export const AddQuestionModal = (props: QuestionModalProps) => {
  if (!props.state.route) {
    return <AddQuestion {...props} />;
  }

  const modalMeta = (() => {
    switch (props.state.route) {
      case "freeResponseQuestion":
        return freeResponse;
      case "imageQuestion":
        return imageQuestion;
      case "multipleChoiceQuestion":
        return multipleChoice;
      case "ratingQuestion":
        return ratingQuestion;
      case "image":
        return image;
      case "text":
        return text;
      default:
        throw new Error(
          `invalid route for add question modal: ${props.state.route}`,
        );
    }
  })();

  return <BaseQuestionModal {...{ ...props, ...modalMeta }} />;
};

const QuestionTypeCard = styled(SelectionCard)<{ last: boolean }>`
  ${({ last }) => !last && "margin-bottom: 15px"};
`;

const questionTypes: QuestionTypeMeta[] = [
  freeResponse,
  imageQuestion,
  multipleChoice,
  ratingQuestion,
];

const AddQuestion = (props: QuestionModalProps) => {
  const { unlockScroll } = useScrollLockContext();
  const [selectedType, setSelectedType] = useState<QuestionType>();

  return (
    <Modal
      isOpen={props.state.isOpen}
      onClose={() => {
        unlockScroll();
        props.dispatch({ type: "closeModal" });
      }}
      variant="large"
      header={
        <>
          <H2 style={{ color: "#333", marginBottom: 10 }}>
            Please select a type of question
          </H2>
          <Body1 style={{ color: "#333", marginBottom: 23 }}>
            There are different types of questions that you can choose to create
            the ideal survey. Each option has a brief explanation.
          </Body1>
        </>
      }
    >
      {questionTypes.map((qType, idx) => (
        <QuestionTypeCard
          {...{
            ...qType,
            onClick: () => {
              setSelectedType(
                selectedType === qType.key ? undefined : qType.key,
              );
            },
            selected: selectedType === qType.key,
            last: idx === questionTypes.length - 1,
          }}
        />
      ))}
      <FlexRow style={{ justifyContent: "flex-end", marginTop: 30 }}>
        <FlexRow>
          <Button
            variant="tertiary"
            onClick={props.onClose}
            style={{ marginRight: 24, color: "#1b0076" }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() =>
              props.dispatch({ type: "setRoute", payload: selectedType })
            }
            disabled={!selectedType}
            style={{ minWidth: 199 }}
          >
            Next
          </Button>
        </FlexRow>
      </FlexRow>
    </Modal>
  );
};
