import { DateTime } from "luxon";
import styled from "styled-components";
import { Font16, FlexColumn, Font26, Font18 } from "../../../styled";
import { svg } from "../../../../svg";
import { useDesktopMediaQuery, useTimeDiff } from "hooks";
import { SvgOpen } from "melodies-source/Svgs/Open";
import { SvgConnected } from "melodies-source/Svgs/Connected";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { doc, getFirestore } from "firebase/firestore";
import { Aggregation, Aggregations } from "models/survey";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import QuestionShowcase from "./QuestionShowcase";
import { useArtistContext } from "Components";
import { truncateString } from "Utils";
import { formatNumber } from "Utils/format";

export const PastSurveyCard = ({
  surveyId,
  surveyName,
  lastResponse,
  responseCount,
}: {
  surveyId: string;
  surveyName: string;
  lastResponse: DateTime;
  responseCount: number;
}) => {
  const history = useHistory();
  const { id: artistGroupId } = useArtistContext();

  return (
    <InfoCardContainer
      onClick={() =>
        history.push(`/${artistGroupId}/reporting/set-fan/surveys/${surveyId}`)
      }
    >
      <PastSurveyBlendedBG />
      <InfoCardContent>
        <GreenCheckContainer>
          <StyledGreenCheck />
        </GreenCheckContainer>
        <FlexColumn>
          <TitleText>{truncateString(surveyName)}</TitleText>
          <LastResponseText>
            Last Response:{" "}
            {responseCount ? lastResponse.toFormat("MM/dd/yy") : "No responses"}
          </LastResponseText>
        </FlexColumn>
        <FlexColumn style={{ marginLeft: "auto" }}>
          <EntryCountText>{formatNumber(responseCount)}</EntryCountText>
          <LastResponseText>
            Response{responseCount !== 1 ? "s" : ""}
          </LastResponseText>
        </FlexColumn>
      </InfoCardContent>
    </InfoCardContainer>
  );
};

const BlendedBG = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  mix-blend-mode: multiply;
  border-radius: 12px;
`;

const EntryCountText = styled(Font26)`
  font-weight: 700;
  line-height: 30px;
  text-align: center;

  ${({ theme }) => theme.media.tablet} {
    font-size: 25px;
    line-height: 25px;
  }

  ${({ theme }) => theme.media.mobile} {
    line-height: 20px;
  }
`;

const GreenCheckContainer = styled.div`
  width: 41px;
  height: 41px;
  margin-right: 21px;

  ${({ theme }) => theme.media.mobile} {
    width: 31px;
    height: 31px;
    margin-right: 12px;
  }
`;

const InfoCardContainer = styled.div`
  position: relative;
  min-height: 81px;
  display: flex;
  align-items: center;
  cursor: pointer;

  ${({ theme }) => theme.media.mobile} {
    height: 51px;
  }
`;

const InfoCardContent = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 16px 0 12px;
  width: 100%;
  height: 100%;
  padding: 16px 12px 17px 24px;

  ${({ theme }) => theme.media.mobile} {
    padding: 10.6px 8px 10.4px 15px;
  }
`;

const LastResponseText = styled(Font16)`
  ${({ theme }) => theme.media.tablet} {
    font-size: 12px;
    line-height: 18px;
  }
`;

const PastSurveyBlendedBG = styled(BlendedBG)`
  background: #3795bd;
`;

const StyledGreenCheck = styled(svg.GreenCheck)`
  margin-right: 21px;

  ${({ theme }) => theme.media.tablet} {
    margin-right: 15px;
  }

  ${({ theme }) => theme.media.mobile} {
    margin-right: 12px;
  }
`;

const TitleText = styled(Font16)`
  font-weight: 700;

  ${({ theme }) => theme.media.tablet} {
    font-size: 15px;
    line-height: 22px;
  }
`;

export const CurrentSurveyCard = ({
  surveyId,
  title,
  responses,
  lastResponse,
  compact,
}: {
  surveyId: string;
  title: string;
  responses: number;
  lastResponse: DateTime;
  compact?: boolean;
}) => {
  const [showcasedQuestion, setShowcasedQuestion] = useState<
    Omit<Aggregation, "options"> & {
      options: { label: string; id: string; count: number }[];
    }
  >();
  const isDesktop = useDesktopMediaQuery();
  const { id: artistGroupId } = useArtistContext();
  const history = useHistory();

  const [aggregations, loading] = useDocumentData(
    doc(getFirestore(), `sts3_surveys/${surveyId}/aggregations/prod`),
  );

  useEffect(() => {
    if (!loading) {
      // first preference is single select
      let showcase = Object.entries(aggregations as Aggregations)
        .map(([id, agg]) => ({ id, ...agg }))
        .find(
          (agg) =>
            agg.type === "SingleSelectQuestion" &&
            Object.keys(agg.options).length > 2 &&
            Object.keys(agg.options).length < 8,
        ) as Aggregation | undefined;

      if (showcase) {
        setShowcasedQuestion({
          ...showcase,
          options: Object.entries(showcase.options)
            .filter(([k]) => k !== "no-response")
            .sort(([_a, a], [_b, b]) => b.count - a.count)
            .map(([_, val]) => ({ ...val, label: val.label ?? '"other"' })),
        });
        return;
      }

      // second is multi select
      showcase = Object.entries(aggregations as Aggregations)
        .map(([id, agg]) => ({ id, ...agg }))
        .find(
          (agg) =>
            agg.type === "MultipleSelectQuestion" &&
            Object.keys(agg.options).length > 2 &&
            Object.keys(agg.options).length < 8,
        ) as Aggregation | undefined;

      if (showcase) {
        setShowcasedQuestion({
          ...showcase,
          options: Object.entries(showcase.options)
            .filter(([k]) => k !== "no-response")
            .sort(([_a, a], [_b, b]) => b.count - a.count)
            .map(([_, val]) => ({ ...val, label: val.label ?? '"other"' })),
        });
        return;
      }

      // finally ranked
      showcase = Object.entries(aggregations as Aggregations)
        .map(([id, agg]) => ({ id, ...agg }))
        .find((agg) => agg.type === "RankedQuestion") as
        | Aggregation
        | undefined;
      if (showcase) {
        setShowcasedQuestion({
          ...showcase,
          options: Object.entries(showcase.options)
            .filter(([k]) => k !== "no-response")
            .map(([_, val]) => val),
        });
        return;
      }
    }
  }, [aggregations, loading]);

  const { diff } = useTimeDiff({ eventTime: lastResponse.toMillis() });

  const lastResponseFormatted = lastResponse.hasSame(DateTime.local(), "day")
    ? "Today"
    : diff.displayString + " ago";

  return (
    <CurrentSurveyContainer
      onClick={() =>
        history.push(`/${artistGroupId}/reporting/set-fan/surveys/${surveyId}`)
      }
    >
      <BlendedBGPast />
      <CurrentSurveyContent compact={compact}>
        <ShowcaseContentColumn style={{ marginBottom: compact ? 0 : 10 }}>
          <div style={{ display: "block", marginBottom: 15 }}>
            <Font26 fontWeight={700} style={{ marginBottom: 5 }}>
              {truncateString(title)}
            </Font26>
            {isDesktop && (
              <SvgOpen
                color="white"
                style={{ marginLeft: "0.75rem", transform: "translateY(3px)" }}
              />
            )}
          </div>
          {compact && (
            <div style={{ marginBottom: 16 }}>
              <Font18 fontWeight={700}>{formatNumber(responses)} </Font18>
              <Font18>Responses • Last Response: </Font18>
              <Font18 fontWeight={700}>{lastResponseFormatted}</Font18>
            </div>
          )}
          {!compact && (
            <>
              <div style={{ marginBottom: 5 }}>
                <Font18 fontWeight={700}>{formatNumber(responses)} </Font18>
                <Font18>Total Respondents</Font18>
              </div>
              <div>
                <Font18>Last Response: </Font18>
                <Font18 fontWeight={700}>{lastResponseFormatted}</Font18>
              </div>
            </>
          )}
        </ShowcaseContentColumn>
        <ShowcaseContentColumn>
          {!!showcasedQuestion && (
            <QuestionShowcase
              showcasedQuestion={showcasedQuestion}
              compact={compact}
              totalResponses={responses}
            />
          )}
        </ShowcaseContentColumn>
      </CurrentSurveyContent>
      <Footer leftAlign={!compact || !isDesktop} style={{ marginTop: 20 }}>
        <SvgConnected style={{ color: "white", marginRight: 5 }} />
        <Font16 fontWeight={isDesktop ? 600 : 400}>
          Currently Collecting Data
        </Font16>
      </Footer>
    </CurrentSurveyContainer>
  );
};

const BlendedBGPast = styled(BlendedBG)`
  background: #3795bd;
`;

const CurrentSurveyContainer = styled.div`
  position: relative;
  width: 100%;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

const CurrentSurveyContent = styled.div<{ compact?: boolean }>`
  position: relative;
  display: flex;
  width: 100%;
  padding: 30px 22px 10px 31px;
  justify-content: space-between;
  gap: 10px;
  ${({ compact }) => compact && "flex-direction: column;"}

  ${({ theme }) => theme.media.tablet} {
    flex-direction: column;
  }
  ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
  }
`;

const Footer = styled.div<{ leftAlign?: boolean }>`
  position: relative;
  bottom: 31px;
  ${({ leftAlign }) => (leftAlign ? "left: 31px" : "right: 22px")};
  display: flex;
  align-items: center;
  justify-content: ${({ leftAlign }) =>
    leftAlign ? "flex-start" : "flex-end"};
`;

const ShowcaseContentColumn = styled(FlexColumn)`
  width: 100%;
`;
