import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";
import {
  SurveyTemplate,
  SurveyTemplateCategoryName,
  SurveyTemplateConfig,
} from "./types";
import { useArtistContext } from "Components";
import { useHistory } from "react-router";
import { getArtistInfo } from "Routes/SetFan/firestore/getArtistInfo";
import { SelectTemplateModal } from "./SelectTemplateModal";
import { useScrollLockContext } from "contexts/ScrollLockContext";
import { SelectCategoryModal } from "./SelectCategoryModal";
import { builderToClass } from "@max/common";
import { AiPromptModal } from "./Prompts/AiPromptModal";
import { createSurveyBuilder } from "Routes/SetFan/firestore/createSurveyBuilder";
import { PromptFormState } from "./defaults/types";
import toast from "react-hot-toast";
import { useExceptionLogging } from "hooks/useExceptionLogging";
import { generateUUID } from "@max/common/dist/setfan/modules/util";

export interface HandleCreateProps {
  config?: SurveyTemplateConfig;
  formState?: PromptFormState;
}

interface TemplateContextProps {
  handleCreate: (v?: HandleCreateProps) => Promise<void>;
  setSelectedTemplate: Dispatch<SetStateAction<SurveyTemplate<unknown>>>;
  selectedTemplate: SurveyTemplate<unknown>;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  templateCategory: SurveyTemplateCategoryName;
  setTemplateCategory: Dispatch<SetStateAction<SurveyTemplateCategoryName>>;
  setModal: Dispatch<SetStateAction<ModalProps>>;
  modal: ModalProps;
  createError?: boolean;
  setCreateError: Dispatch<SetStateAction<boolean>>;
}

interface ModalProps {
  aiPrompt?: boolean;
  selectCategory?: boolean;
  selectTemplate?: boolean;
}

export const TemplateContext = createContext<TemplateContextProps>(
  {} as TemplateContextProps,
);

interface TemplateProviderProps {
  children?: React.ReactNode;
}

export const TemplateProvider = ({ children }: TemplateProviderProps) => {
  const [selectedTemplate, setSelectedTemplate] =
    useState<SurveyTemplate<unknown>>();
  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState<ModalProps>({});
  const [templateCategory, setTemplateCategory] =
    useState<SurveyTemplateCategoryName>();
  const { id: artistGroupId, name: artist } = useArtistContext();
  const { unlockScroll } = useScrollLockContext();
  const history = useHistory();
  const [createError, setCreateError] = useState<boolean>();
  const { logException } = useExceptionLogging();

  const handleCreate = async (options: HandleCreateProps) => {
    if (!selectedTemplate?.template && !options?.config) {
      return;
    }
    const { config, formState } = options ?? {};
    try {
      setIsLoading(true);

      const artistInfo =
        selectedTemplate?.templateId === "dreamSetlist"
          ? await getArtistInfo({ artistGroupId, artistName: artist })
          : undefined;

      let template: SurveyTemplateConfig;

      if (selectedTemplate.template) {
        template = selectedTemplate?.template(
          {
            ...artistInfo,
            artist,
          },
          formState,
        );
      }

      if (config?.fields) {
        template = {
          ...template,
          fields: { ...template?.fields, ...config.fields },
        };
      }

      if (config?.surveyQuestions) {
        for (const question of config.surveyQuestions) {
          if (typeof question === "object" && !Array.isArray(question)) {
            const templateIdx = template?.surveyQuestions?.findIndex(
              ({ id }) => id === question.id,
            );
            if (templateIdx > -1) {
              template.surveyQuestions.splice(templateIdx, 1, {
                ...question,
                ...template.surveyQuestions[templateIdx],
                id: generateUUID(),
              });
            } else {
              template = {
                ...template,
                surveyQuestions: (template.surveyQuestions || []).concat([
                  question,
                ]),
              };
            }
          }
        }
      }

      if (template?.surveyQuestions?.length) {
        template.surveyQuestions = template.surveyQuestions.map((sq) =>
          builderToClass({ ...sq, required: true }).toBuilderConfig(),
        );
      }

      const { survey } = await createSurveyBuilder({
        artistGroupId,
        artistName: artist,
        ...template,
        template: selectedTemplate.templateId || "buildMyOwn",
      });

      history.push(`surveys/${survey.id}/wizard`);
    } catch (error) {
      console.error(error);

      logException(error, {
        action: "template-create",
        promptId: selectedTemplate.prompt?.promptId(formState),
      });
      toast.error(
        "We encountered an issue when creating your survey. Please Try again or contact our support team for help.",
        { duration: 15000 },
      );
      setCreateError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const values = {
    selectedTemplate,
    setSelectedTemplate,
    templateCategory,
    setTemplateCategory,
    handleCreate,
    isLoading,
    setIsLoading,
    modal,
    setModal,
    createError,
    setCreateError,
  };

  return (
    <TemplateContext.Provider value={values}>
      <SelectCategoryModal
        onClose={() => {
          unlockScroll();
          setModal({ selectCategory: false });
        }}
        onSelect={() => setModal({ selectTemplate: true })}
      />
      <SelectTemplateModal
        onBack={() => {
          setSelectedTemplate(undefined);
          setModal({ selectCategory: true });
        }}
      />
      <AiPromptModal onBack={() => setModal({ selectTemplate: true })} />

      {children}
    </TemplateContext.Provider>
  );
};

export const useTemplates = () => useContext(TemplateContext);
