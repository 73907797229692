import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { DocumentModel, useDocumentModel } from "hooks/useModel";
import { DateTime } from "luxon";
import { useHistory } from "react-router-dom";
import {
  Model,
  validator,
  TransformValidateDateTime,
  TransformValidateModel,
} from "@musicaudienceexchange/toccata";
import {
  getFirestore,
  doc,
  collection,
  getCountFromServer,
  query,
  getDoc,
} from "firebase/firestore";
import { SocialLinkName, StreamingService } from "@max/common";
import { EntriesTotals, useEventEntries } from "hooks/useEventEntries";
import { EntriesDocument } from "@max/common/analytics";
import { useTimeContext } from "Components";

export class Coordinates extends Model {
  @validator.IsLatitude()
  latitude?: number;

  @validator.IsLongitude()
  longitude?: number;
}

export const CsvExportStatuses = [
  "initial",
  "generating",
  "available",
  "failed",
] as const;

export type CsvExportStatus = (typeof CsvExportStatuses)[number];

class Event extends Model {
  @validator.IsNumber()
  @validator.IsOptional()
  version?: number;

  @TransformValidateDateTime()
  startsAt: DateTime;

  @TransformValidateModel(Coordinates)
  coordinates: Coordinates;

  @validator.IsNotEmpty()
  @validator.IsString()
  venue: string;

  @validator.IsNotEmpty()
  @validator.IsString()
  address: string;

  @TransformValidateDateTime()
  @validator.IsOptional()
  endedAt: DateTime | null;

  @validator.IsOptional()
  entriesExportStatus?: CsvExportStatus;
}

interface PriorityProps {
  [key: string]: number;
}

const ViewPropTypes = [
  "requests",
  "encore",
  "support",
  "entries",
  "dashboard",
  "sweeps",
  "auction",
  "conversation",
  "settings",
] as const;
export type ViewProps = (typeof ViewPropTypes)[number];

interface ObjectLiteral<T> {
  [key: string]: T;
}

interface Visits {
  total: number;
  unique: number;
}

interface ClicksData {
  merch?: ObjectLiteral<number>;
  social?: ObjectLiteral<number>;
  streaming?: ObjectLiteral<number>;
  register?: ObjectLiteral<number>;
}

interface ClickDetails {
  name: string;
  count: number;
}

interface ClickCategory {
  name: string;
  total: number;
  detail: ClickDetails[];
}

interface Vote<T = number> {
  label?: string;
  options?: Record<string, string>;
  votes?: Record<string, T>;
  total?: number;
}

type Votes<T = number> = {
  total?: number;
  items?: {
    vote?: Vote<T>;
  } & { [id: string]: Vote<T> | undefined };
};

interface Impressions {
  [label: string]: number;
}

type EmailAnalyticsClick = {
  [key in `social.${SocialLinkName}`]?: number;
} & {
  [key in `streaming.${StreamingService}`]?: number;
} & {
  [key: `merch.${string}`]: number;
} & {
  setfan_postshow?: number;
  unsubscribe?: number;
  other?: number;
};

interface EmailAnalytics {
  opened?: number;
  delivered?: number;
  clicked?: EmailAnalyticsClick;
}

interface ArtistEventContextProps {
  event: DocumentModel<Event>;
  eventId: string;
  eventLoading: boolean;
  setPriority?: (val: PriorityProps) => void;
  priority?: any;
  requestsTotalCents?: number;
  view: ViewProps;
  setView: React.Dispatch<React.SetStateAction<ViewProps>>;
  encorePaymentAllowed?: boolean;
  proximityLimit?: number;
  totalEntriesInVenue?: number;
  auctionItems?: any[];
  auctionWinners?: any[];
  entriesTotals?: EntriesTotals;
  entriesTimes: EntriesDocument["times"];
  entriesZips: EntriesDocument["zips"];
  entriesLoading?: boolean;
  entriesError?: boolean;
  clicks?: ClickCategory[];
  clicksLoading?: boolean;
  clicksError?: boolean;
  impressions?: Impressions;
  impressionsLoading?: boolean;
  impressionsError?: boolean;
  visits?: Visits;
  visitsLoading?: boolean;
  visitsError?: boolean;
  votes?: Votes;
  votesLoading?: boolean;
  votesError?: boolean;
  emailAnalytics?: EmailAnalytics;
  emailAnalyticsLoading?: boolean;
  emailAnalyticsError?: boolean;
}

export const ArtistEventContext = createContext<ArtistEventContextProps>(
  {} as ArtistEventContextProps,
);

export const ArtistEventController = ({ eventId, children }) => {
  const [view, setView] = useState<ViewProps>("dashboard");
  const [eventSnapshot, eventLoading] = useDocumentModel(
    Event,
    doc(getFirestore(), `setlive_events/${eventId}`),
  );

  const event = {
    ...eventSnapshot,
    startsAt: eventSnapshot?.startsAt?.toLocal(),
  };

  const history = useHistory();

  if (!eventLoading && event === undefined) {
    history.push("/");
  }
  const services = {
    event,
    eventLoading,
    view,
    setView,
    eventId,
    children,
  };

  // if (!event.type) {
  //   return null; //TODO: actually validate this loading process/show a loader
  // }

  return <ArtistEventProviderLite {...services} />;
};

const REFRESH_TIME = 0;

export const ArtistEventProviderLite = ({
  eventId,
  event,
  eventLoading,
  view,
  setView,
  children,
}) => {
  const {
    entriesTotals,
    entriesTimes,
    entriesZips,
    entriesLoading,
    entriesError,
    reload: getEntries,
  } = useEventEntries(eventId, event.version, false);
  const [clicks, setClicks] = useState<
    ArtistEventContextProps["clicks"] | null
  >(null);
  const [clicksLoading, setClicksLoading] = useState(true);
  const [clicksError, setClicksError] = useState(false);
  const [emailAnalytics, setEmailAnalytics] = useState<EmailAnalytics | null>(
    null,
  );
  const [emailAnalyticsLoading, setEmailAnalyticsLoading] = useState(true);
  const [emailAnalyticsError, setEmailAnalyticsError] = useState(false);
  const [visits, setVisits] = useState<Visits | null>(null);
  const [visitsLoading, setVisitsLoading] = useState(true);
  const [visitsError, setVisitsError] = useState(false);
  const [votes, setVotes] = useState<Votes | null>(null);
  const [votesLoading, setVotesLoading] = useState(true);
  const [votesError, setVotesError] = useState(false);
  const [impressions, setImpressions] = useState<Impressions | null>(null);
  const [impressionsLoading, setImpressionsLoading] = useState(true);
  const [impressionsError, setImpressionsError] = useState(false);
  const [isUpdating, setIsUpdating] = useState(true);
  const [updatedAt, setUpdatedAt] = useState(0);
  const { time } = useTimeContext();
  const milliseconds = time.toMillis();

  const getClicks = async () => {
    try {
      setClicksError(null);
      const clicksSnapshot = await getDoc(
        doc(getFirestore(), `set_fresh_events/${eventId}/analytics/click`),
      );

      if (clicksSnapshot?.exists) {
        const clicksData: ClicksData = clicksSnapshot.data();
        if (clicksData?.register) delete clicksData?.register;

        const labels = {
          merch: "Merchandise",
          social: "Social",
          streaming: "Streaming",
          url: "URL",
          facebook: "Facebook",
          instagram: "Instagram",
          tiktok: "TikTok",
          twitter: "Twitter",
          amazon: "Amazon Music",
          apple: "Apple Music",
          deezer: "Deezer",
          spotify: "Spotify",
          youtube: "Youtube",
          tidal: "Tidal",
          pandora: "Pandora",
        };

        const categoriesToOmit = ["tab"];
        const keysToOmit = ["open", "title", "subtitle"];
        const categoriesWithLabelKey = ["merch", "tab", "url"];

        const data = clicksData
          ? (Object.entries(clicksData) as Entries<typeof clicksData>)
              .filter(
                ([key, value]) =>
                  !categoriesToOmit.includes(key) &&
                  Object.keys(value).length > 0,
              )
              .map(([key, value]) => {
                for (const [k] of Object.entries(value)) {
                  if (keysToOmit.includes(k)) delete value[k];
                }
                return {
                  name: labels[key] ?? key,
                  total: Object.values(value).reduce(
                    (total, elem) => total + elem,
                    0,
                  ),
                  detail: Object.entries(value)
                    .map(([key2, value2]) => ({
                      name: categoriesWithLabelKey.includes(key)
                        ? key2
                        : labels[key2] ?? key2,
                      count: value2,
                    }))
                    .sort((a, b) => a.name.localeCompare(b.name)),
                };
              })
              .sort((a, b) => a.name.localeCompare(b.name))
          : [];
        setClicks(data);
      }
    } catch (error) {
      console.error(error);
      setClicksError(true);
    } finally {
      setClicksLoading(false);
    }
  };

  const getEmailAnalytics = async () => {
    try {
      setEmailAnalyticsError(false);
      const emailAnalyticsSnapshot = await getDoc(
        doc(getFirestore(), `set_fresh_events/${eventId}/analytics/email`),
      );

      if (emailAnalyticsSnapshot?.exists()) {
        setEmailAnalytics(emailAnalyticsSnapshot.data());
      }
    } catch (error) {
      console.error(error);
      setEmailAnalyticsError(true);
    } finally {
      setEmailAnalyticsLoading(false);
    }
  };

  const getVisits = async () => {
    try {
      setVisitsError(false);
      const visitsSnapshot = await getDoc(
        doc(getFirestore(), `set_fresh_events/${eventId}/analytics/visit`),
      );

      const uniqueVisits = await getCountFromServer(
        query(
          collection(
            getFirestore(),
            `set_fresh_events/${value.eventId}/analytics/visit/users`,
          ),
        ),
      );

      if (visitsSnapshot?.exists()) {
        const data: Visits = {
          total: visitsSnapshot.data().total || 0,
          unique: uniqueVisits?.data().count || 0,
        };
        setVisits(data);
      }
    } catch (error) {
      console.error(error);
      setVisitsError(true);
    } finally {
      setVisitsLoading(false);
    }
  };

  const getVotes = async () => {
    try {
      setVotesError(false);
      const votesSnapshot = await getDoc(
        doc(getFirestore(), `set_fresh_events/${eventId}/analytics/votes`),
      );

      if (votesSnapshot?.exists()) {
        const voteItems = votesSnapshot.data();
        for (const [k, v] of Object.entries(voteItems ?? {})) {
          // If a "vote" object doesn't have any keys, it is invalid
          if (Object.keys(v).length < 1) {
            delete voteItems[k];
          }
        }

        const data: Votes = {
          total: 0,
          items: voteItems,
        };

        if (data.items) {
          Object.keys(data.items).forEach((key) => {
            data.items[key].total = Object.values(
              data.items[key]?.votes ?? {},
            ).reduce((t, v) => t + v, 0);
            data.total += data.items[key].total;
          });
          setVotes(data);
        }
      }
    } catch (error) {
      console.error(error);
      setVotesError(true);
    } finally {
      setVotesLoading(false);
    }
  };

  const getImpressions = async () => {
    try {
      setImpressionsError(false);
      const impressionsSnapshot = await getDoc(
        doc(getFirestore(), `set_fresh_events/${eventId}/analytics/card`),
      );

      if (impressionsSnapshot?.exists()) {
        const data: Impressions = impressionsSnapshot.data();
        for (const [k, v] of Object.entries(data)) {
          if (typeof v === "object") delete data[k];
        }
        setImpressions(data);
      }
    } catch (error) {
      console.error(error);
      setImpressionsError(true);
    } finally {
      setImpressionsLoading(false);
    }
  };

  const getAnalytics = () => {
    const functions = [
      getEntries,
      getClicks,
      getEmailAnalytics,
      getVisits,
      getVotes,
      getImpressions,
    ];

    setIsUpdating(true);
    Promise.allSettled(
      functions.map((fn) => new Promise((res) => fn().then(() => res("")))),
    ).then(() => {
      setUpdatedAt(Date.now());
      setIsUpdating(false);
    });
  };

  useEffect(() => {
    if (!eventLoading) getAnalytics();
  }, [eventLoading]);

  useEffect(() => {
    if (!isUpdating && milliseconds - updatedAt > REFRESH_TIME) {
      getAnalytics();
    }
  }, [milliseconds, updatedAt, isUpdating]);

  const value = useMemo(
    () => ({
      event,
      eventId,
      eventLoading,
      view,
      setView,
      entriesTotals,
      entriesTimes,
      entriesZips,
      entriesLoading,
      entriesError,
      proximityLimit: event?.sweeps?.proximityLimitInMiles ?? 1.25,
      clicks,
      clicksLoading,
      clicksError,
      visits,
      visitsLoading,
      visitsError,
      votes,
      votesLoading,
      votesError,
      impressions,
      impressionsLoading,
      impressionsError,
      emailAnalytics,
      emailAnalyticsLoading,
      emailAnalyticsError,
    }),
    [updatedAt],
  );

  return (
    <ArtistEventContext.Provider value={value}>
      {children}
    </ArtistEventContext.Provider>
  );
};

export const useArtistEventContext = () => useContext(ArtistEventContext);
