import { useArtistContext } from "Components";
import styled, { css } from "styled-components";
import { useHistory } from "react-router";
import ProgressCircle from "Components/ProgressCircle";
import StatusCard from "./StatusCard";
import { DateTime } from "luxon";
import { Font16, Font30 } from "Routes/styled";
import { truncateString } from "Utils";
import LiveNow from "Components/RecordingIcon";
import { useNextShow, useDesktopMediaQuery, useTimeDiff } from "hooks";
import { svg } from "svg";
import Skeleton from "react-loading-skeleton";
import { useCustomAppContext } from "contexts/CustomAppContext";
import { formatNumber } from "Utils/format";

export const StatusCards = () => {
  const { privateData, privateProfileData } = useArtistContext();
  const data = privateData?.data();
  const profileData = privateProfileData?.data();

  const profileCompletionPercentage = Math.round(
    (profileData?.completion?.total || 0) * 100 ?? 0,
  );

  const isDesktop = useDesktopMediaQuery();

  const { nextShow, loading: loadingNextShow } = useNextShow();
  const { customApp } = useCustomAppContext();

  const displayAddress =
    nextShow && truncateString(nextShow.displayAddress || nextShow.address);

  return (
    <Container>
      <svg.DashboardHeaderBG />
      <Content hasNextShow={!!nextShow}>
        <Font30 fontWeight={700}>Dashboard</Font30>
        <CardsContainer>
          {loadingNextShow ? (
            <NextShowLoader />
          ) : (
            !!nextShow && (
              <NextShow
                nextEventDate={nextShow.startsAt}
                nextEventLocation={displayAddress}
                nextEventVenue={nextShow.venue}
                eventId={nextShow.id}
              />
            )
          )}
          {isDesktop ? (
            <>
              <Contacts totalContacts={data?.totalContacts} />
              {!customApp && (
                <FanProfile percentageComplete={profileCompletionPercentage} />
              )}
            </>
          ) : (
            <SecondRow>
              <Contacts totalContacts={data?.totalContacts} />
              {!customApp && (
                <FanProfile percentageComplete={profileCompletionPercentage} />
              )}
            </SecondRow>
          )}
        </CardsContainer>
      </Content>
    </Container>
  );
};

const Contacts = ({ totalContacts }: { totalContacts?: number }) => {
  const { id: artistGroupId } = useArtistContext();
  const isDesktop = useDesktopMediaQuery();
  const history = useHistory();
  const { customApp } = useCustomAppContext();

  return (
    <ContactsStatusCard
      footerText="View Contacts"
      onClickFooter={() => history.push(`/${artistGroupId}/fans`)}
    >
      <HeaderText>{!customApp && "Fan"} Contact Info</HeaderText>
      <ContactsNumberContainer>
        <StyledContactsIcon
          style={{
            marginRight: isDesktop ? 0 : "10px",
          }}
        />
        <NumberText>{formatNumber(totalContacts ?? 0)}</NumberText>
      </ContactsNumberContainer>
    </ContactsStatusCard>
  );
};

const FanProfile = ({ percentageComplete }: { percentageComplete: number }) => {
  const { id: artistGroupId } = useArtistContext();
  const isDesktop = useDesktopMediaQuery();
  const history = useHistory();

  return (
    <FanProfileStatusCard
      footerText="See Fan Profile"
      onClickFooter={() =>
        history.push(`/${artistGroupId}/reporting/fan-profile`)
      }
    >
      <HeaderText style={{ marginBottom: isDesktop ? 20 : 0 }}>
        Fan Profile
      </HeaderText>
      <ProfileCompletionContainer>
        <StyledProgressCircle percentage={percentageComplete} color="#953a93" />
        <ProfileTextContainer>
          <PercentageText>{percentageComplete}%</PercentageText>
          <StatusText>Complete</StatusText>
        </ProfileTextContainer>
      </ProfileCompletionContainer>
    </FanProfileStatusCard>
  );
};

const FanProfileStatusCard = styled(StatusCard)(({ theme }) => {
  if (!theme.custom) return null;
  const { background, color } = theme.custom.pages.dashboard.cards.fanProfile;
  return css`
    background: ${background};

    * {
      color: ${color} !important;
    }

    rect {
      fill: ${color} !important;
    }
  `;
});

const StyledProgressCircle = styled(ProgressCircle)`
  ${({ theme }) => theme.media.mobile} {
    width: 55px;
    margin-right: 6px;
  }
`;

const NextShowLoader = () => {
  const isDesktop = useDesktopMediaQuery();
  const { customApp } = useCustomAppContext();

  return (
    <NextShowCard
      footerText={`loading next ${
        customApp?.event.type.singular.toLowerCase() || "show"
      }...`}
    >
      <HeaderText>
        <Skeleton width={125} />
      </HeaderText>
      {isDesktop && (
        <NextEventVenue>
          <Skeleton width={150} />
        </NextEventVenue>
      )}
      <TimeUntilContainer>
        <TimeUntilText>
          <Skeleton width={140} />
        </TimeUntilText>
      </TimeUntilContainer>
      <div>
        <StatusText>
          <Skeleton width={isDesktop ? 100 : 200} />
        </StatusText>
      </div>
    </NextShowCard>
  );
};

const NextShow = ({
  eventId,
  nextEventDate,
  nextEventLocation,
  nextEventVenue,
}: {
  eventId: string;
  nextEventDate: number;
  nextEventLocation: string;
  nextEventVenue: string;
}) => {
  const isDesktop = useDesktopMediaQuery();
  const { customApp } = useCustomAppContext();

  const { diff: timeUntilEvent, time } = useTimeDiff({
    eventTime: nextEventDate,
  });

  const isLiveNow = nextEventDate < time.toMillis();

  return (
    <NextShowCard
      footerText={`View  ${customApp?.event.type.singular || "Show"}`}
      onClickFooter={() =>
        window.open(
          `${
            customApp?.onsiteAppUrl || process.env.REACT_APP_BS_URL
          }/${eventId}`,
        )
      }
    >
      <HeaderText>
        {isLiveNow
          ? `Current ${customApp?.event.type.singular || "Show"}`
          : `Next  ${customApp?.event.type.singular || "Show"}`}
      </HeaderText>
      {isDesktop && <NextEventVenue>{nextEventVenue}</NextEventVenue>}
      <TimeUntilContainer>
        <TimeUntilText>
          {!isLiveNow && timeUntilEvent.displayString}
        </TimeUntilText>
        {isLiveNow && <LiveNow label={<LiveNowText>Live Now</LiveNowText>} />}
      </TimeUntilContainer>
      <div>
        {!isDesktop && (
          <NextEventVenue style={{ marginRight: 5 }}>
            {nextEventVenue}
          </NextEventVenue>
        )}
        <StatusText>{truncateString(nextEventLocation, 50)}</StatusText>
        <StatusText>
          {" "}
          {DateTime.fromMillis(nextEventDate).toLocaleString(
            DateTime.TIME_SIMPLE,
          )}
        </StatusText>
      </div>
    </NextShowCard>
  );
};

const CardsContainer = styled.div`
  position: relative;
  display: flex;
  max-width: 100%;
  flex-wrap: wrap;
  padding: 14px 0 0 0;

  @media (min-width: 769px) and (max-width: 1000px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    padding: 40px 38px 0;
    margin-bottom: 15px;
    row-gap: 20px;
  }
  @media (min-width: 1000px) and (max-width: 1400px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    gap: 10px;
  }
`;

const ContactsNumberContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ theme }) => theme.media.mobile} {
    align-items: center;
    flex-direction: row;
  }
`;

const ContactsStatusCard = styled(StatusCard)(
  () => css`
    margin-right: 20px;

    ${({ theme }) => theme.media.mobile} {
      margin-right: 6px;
      margin-bottom: 0px;
    }
    @media (min-width: 1000px) and (max-width: 1263px) {
      margin-bottom: 20px;
    }
  `,
  ({ theme }) => {
    if (!theme.custom) return null;
    const { background, color } =
      theme.custom.pages.dashboard.cards.fanContacts;
    return css`
      background: ${background};

      * {
        color: ${color} !important;
      }

      rect,
      path {
        fill: ${color} !important;
      }
    `;
  },
);

const Container = styled.div`
  width: 100%;
`;

const Content = styled.div<{ hasNextShow?: boolean }>`
  position: relative;
  padding: 37px 46px;

  ${({ theme }) => theme.media.tablet} {
    padding-right: 20px;
    padding-left: 20px;

    ${({ hasNextShow }) => !hasNextShow && "padding: 37px 46px 17px;"}
  }

  ${({ theme }) => theme.media.mobile} {
    padding: 22px;
  }
`;

const HeaderText = styled.span`
  font-family: Poppins;
  font-size: 25px;
  line-height: 35px;
  margin-bottom: 10px;
  font-weight: 700;
  color: #1c0d6a;

  ${({ theme }) => theme.media.mobile} {
    font-size: 14.3px;
    line-height: 25.7px;
    margin-bottom: 0px;
  }
`;

const LiveNowText = styled.div`
  font-family: Poppins;
  color: #953a93;
  font-size: min(3vw, 45px);
  line-height: 150%;
  font-weight: 700;

  ${({ theme }) => theme.media.mobile} {
    font-size: 25.6px;
    line-height: 38.4px;
  }
`;

const NextEventVenue = styled(Font16)`
  font-weight: 600;
  color: #1b0076;
`;

const NextShowCard = styled(StatusCard)(
  () => css`
    margin-right: 20px;
    margin-bottom: 0px;
    width: 298px;

    ${({ theme }) => theme.media.mobile} {
      margin-right: 0px;
      margin-bottom: 7px;
      width: 100%;
    }
    @media (min-width: 1000px) and (max-width: 1263px) {
      margin-bottom: 20px;
    }
    @media (min-width: 769px) and (max-width: 1400px) {
      width: 100%;
    }
  `,
  ({ theme }) => {
    if (!theme.custom) return null;
    const { background, color } = theme.custom.pages.dashboard.cards.nextShow;
    return css`
      background: ${background};

      * {
        color: ${color} !important;
      }

      rect {
        fill: ${color} !important;
      }
    `;
  },
);

const NumberText = styled.div`
  font-family: Poppins;
  color: #953a93;
  font-size: 40px;
  line-height: 67.5px;
  font-weight: 700;

  ${({ theme }) => theme.media.mobile} {
    font-size: 28px;
    line-height: 38.4px;
    margin-bottom: -10px;
  }
`;

const PercentageText = styled(NumberText)`
  margin-left: 5px;
  line-height: 55px;

  ${({ theme }) => theme.media.mobile} {
    margin-left: 0px;
    margin-top: 5px;
    line-height: 35px;
  }
`;

const ProfileCompletionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ theme }) => theme.media.mobile} {
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

const ProfileTextContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.media.desktop} {
    position: absolute;
    top: 100px;
  }

  ${({ theme }) => theme.media.mobile} {
    height: 50px;
  }
`;

const SecondRow = styled.div`
  display: flex;

  ${({ theme }) => theme.media.mobile} {
    width: 100%;
  }
`;

const StatusText = styled(Font16)`
  font-weight: 500;
  color: #1b0076;
`;

export const StyledContactsIcon = styled(svg.ContactsIcon)`
  margin-right: 0px;
  margin-bottom: -15px;

  ${({ theme }) => theme.media.mobile} {
    margin-right: 5px;
    margin-bottom: -10px;
    width: 40px;
  }
`;

const TimeUntilContainer = styled.div`
  position: relative;
`;

const TimeUntilText = styled(NumberText)`
  margin-bottom: 5px;

  ${({ theme }) => theme.media.mobile} {
    margin-bottom: 0px;
  }
`;
