import { useExceptionLogging } from "./useExceptionLogging";
import useUserIdToken from "./useUserIdToken";

export const useSpotifySearch = () => {
  const spotifyToken = useUserIdToken();
  const { logException } = useExceptionLogging();

  const search = async (term: string, type: "track" | "album" = "track") => {
    if (!spotifyToken || !term) {
      return undefined;
    }

    try {
      const url = new URL(
        "/v1/search",
        process.env.REACT_APP_SPOTIFY_SEARCH_URL.replace(/\/$/, ""),
      );

      url.searchParams.set("q", term);
      url.searchParams.set("type", type);

      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${spotifyToken}`,
        },
      });

      return await response.json();
    } catch (err) {
      console.error(err);
      logException(err, { service: "spotify", action: "search", term, type });
      return undefined;
    }
  };

  const fetchAlbumTracks = async (id: string) => {
    if (!spotifyToken) {
      return undefined;
    }

    try {
      const url = new URL(
        `/v1/albums/${id}/tracks`,
        process.env.REACT_APP_SPOTIFY_SEARCH_URL.replace(/\/$/, ""),
      );

      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${spotifyToken}`,
        },
      });

      return await response.json();
    } catch (err) {
      console.error(err);
      logException(err, {
        service: "spotify",
        action: "fetch_album_tracks",
        albumId: id,
      });
      return undefined;
    }
  };

  return { search, fetchAlbumTracks };
};
