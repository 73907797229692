import {
  SurveyBuilderOptIn,
  SurveyBuilderRootDocument,
  defaultTermsAndPrivacyOptIn,
  defaultSmsOptIn,
  VirtualOptIns,
  styleLabelText,
} from "@max/common";
import { AnalysisDocument } from "@max/common/src/artists/analysis";
import {
  CollectionReference,
  DocumentReference,
  collection,
  doc,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  SurveyTemplateConfig,
  SurveyTemplateName,
} from "../dashboard/Components/Templates/types";
import { removeUndefined } from "Utils";

interface CreateSurveyBuilderOptions extends SurveyTemplateConfig {
  artistGroupId: string;
  artistName: string;
  template?: SurveyTemplateName | "buildMyOwn";
}

interface OptInsResponse {
  optIns: SurveyBuilderOptIn[];
  disclaimers?: string[];
  virtualOptIns?: VirtualOptIns;
}

export const createSurveyBuilder = async (
  params: CreateSurveyBuilderOptions,
) => {
  const survey = doc(
    collection(getFirestore(), "set_fan_builder_surveys"),
  ) as DocumentReference<SurveyBuilderRootDocument>;

  const analysisSnap = await getDocs(
    query(
      collection(
        getFirestore(),
        "artist_groups",
        params.artistGroupId,
        "analysis",
      ) as CollectionReference<AnalysisDocument>,
      orderBy("createdAt", "desc"),
      limit(1),
    ),
  );

  if (!analysisSnap.empty) {
    const [analysisDoc] = analysisSnap.docs;
    const analysisImage = analysisDoc.data().images?.[0];
    const theme = analysisImage?.templates?.[0]?.themes?.find(
      ({ name }) => name === "Cards",
    );
    params.fields = {
      ...params.fields,
      ...(analysisImage?.url && {
        image: { content: { en: analysisImage.url } },
      }),
      ...(theme?.secondary && {
        backgroundColor: {
          content: theme.secondary.hex,
        },
      }),
      ...(theme?.primary && {
        buttonColor: {
          content: theme.primary.hex,
        },
      }),
    };
  }
  const defaultOptIns = [
    defaultTermsAndPrivacyOptIn(params.artistName),
    defaultSmsOptIn(params.artistName),
  ];

  try {
    const { data } = await httpsCallable<
      { artistGroupId: string; artistName: string },
      OptInsResponse
    >(
      getFunctions(),
      "setfan-buildCustomOptIns",
    )({
      artistGroupId: params.artistGroupId,
      artistName: params.artistName,
    });

    params.optIns = [...defaultOptIns, ...data.optIns];

    if (data.disclaimers?.length) {
      params.disclaimers = data.disclaimers.map(styleLabelText);
    }
    if (Object.keys(data.virtualOptIns ?? {}).length) {
      params.virtualOptIns = data.virtualOptIns;
    }
  } catch (err) {
    console.error(err);
    params.optIns = [...defaultOptIns];
  }

  const version = doc(
    collection(getFirestore(), `set_fan_builder_surveys/${survey.id}/versions`),
  );

  await setDoc(survey, {
    artistGroupId: params.artistGroupId,
    createdAt: serverTimestamp(),
    draftId: version.id,
    status: "draft",
  } as SurveyBuilderRootDocument);

  await setDoc(version, removeUndefined(params));

  return { survey };
};
