import { ReactComponent as SvgDropdownNav } from "assets/svg/dropdown-nav.svg";
import { QuestionModalProps, QuestionTypeMeta } from "./BaseQuestionModal";
import styled from "styled-components";
import { FlexColumn } from "Components/Flex";
import { useState } from "react";
import { SvgHelp } from "melodies-source/Svgs/Help";
import { H3, LinkText, Td } from "melodies-source/Text";
import { TextInput } from "Components";
import { AddQuestionFooter } from "./Footer";
import { ReactComponent as SvgSeries } from "assets/svg/series.svg";
import { Button } from "melodies-source/Button";
import { SvgAddAlt } from "melodies-source/Svgs/AddAlt";
import { CenterFlexRow, FlexRow } from "Routes/styled";
import { Selectable } from "melodies-source/Selectable";
import {
  DropdownModule,
  MultiSelectQuestionModule,
  SingleSelectQuestionModule,
} from "@max/common/dist/setfan";
import { SvgCloseLarge } from "melodies-source/Svgs/CloseLarge";
import { Tooltip } from "melodies-source/Tooltip";

export const MultipleChoiceModalModal = ({
  existing,
  ...props
}: Omit<QuestionModalProps<MultiSelectQuestionModule>, "state">) => {
  const [questionText, setQuestionText] = useState(existing?.label.en ?? "");
  const [options, setOptions] = useState<Option[]>(
    existing?.options
      ?.filter((opt) => !opt.userDefined)
      .map((opt) => ({
        id: opt.id || window.crypto.randomUUID(),
        value: opt.label.en,
        other: opt.userDefined,
      })) ?? [
      { id: window.crypto.randomUUID(), value: "" },
      { id: window.crypto.randomUUID(), value: "" },
    ],
  );
  const [selectOnlyOneOption, setSelectOnlyOneOption] = useState(
    existing?.type !== "MultipleSelectQuestion" ?? true,
  );
  const [addOtherOption, setAddOtherOption] = useState(
    existing?.options.some((opt) => opt.userDefined),
  );
  const [required, setRequired] = useState(!!existing?.required);
  const [errors, setErrors] = useState<ValidationErrors>({
    questionText: "",
    options: {},
  });
  const [validationTriggered, setValidationTriggered] = useState(false);

  const useDropdownModule = selectOnlyOneOption && options.length > 7;

  const onAdd = () => {
    setValidationTriggered(true);

    // handle errors
    setErrors({
      options: options.reduce((acc, opt) => {
        if (!opt.value) {
          acc[opt.id] = "Please enter an option (2 minimum)";
        }
        return acc;
      }, {}),
      questionText: !questionText
        ? "This is a required field and cannot be blank"
        : "",
    });

    if (
      errors.questionText ||
      Object.values(errors.options).reduce((acc, err) => (acc += err.length), 0)
    ) {
      return;
    }
    // end handle errors

    let otherOptions = [];
    // can't have an 'other' option with a dropdown
    if (addOtherOption && !useDropdownModule) {
      otherOptions.push({
        id:
          existing?.options.find((opt) => opt.userDefined)?.id ||
          window.crypto.randomUUID(),
        userDefined: true,
        label: { en: "Other" },
      });
    }

    // if the question type changes between a multi and single select, we need to assign
    // a new id (passing undefined) because the answer type changed (string -> string[]
    // or string[] -> string).
    const newModuleCompatible = (() => {
      if (
        selectOnlyOneOption &&
        (existing?.type === "Dropdown" ||
          existing?.type === "SingleSelectQuestion")
      ) {
        return true;
      }
      if (!selectOnlyOneOption && existing?.type === "MultipleSelectQuestion") {
        return true;
      }
      return false;
    })();

    const modulePayload = {
      id: newModuleCompatible ? existing.id : undefined,
      label: { en: questionText },
      options: [
        ...options.map((opt) => ({
          id: opt.id || window.crypto.randomUUID(),
          label: { en: opt.value },
        })),
        ...otherOptions,
      ],
      // single select
      required,
      // multi select
      requiredMin: required ? 1 : undefined,
    };

    const newModule = !selectOnlyOneOption
      ? new MultiSelectQuestionModule(modulePayload)
      : useDropdownModule
      ? new DropdownModule(modulePayload)
      : new SingleSelectQuestionModule(modulePayload);

    props.dispatch(
      existing
        ? {
            type: "updateModule",
            payload: { idToReplace: existing?.id, module: newModule },
          }
        : {
            type: "addModule",
            payload: { module: newModule },
          },
    );

    props.dispatch({ type: "closeModal" });
  };

  return (
    <>
      <QuestionCard>
        <IconContainer>
          <SvgHelp style={{ color: "#1B0076", width: 29, height: 29 }} />
        </IconContainer>
        <H3 style={{ color: "#1B0076", marginBottom: 24 }}>Question</H3>
        <TextInput
          label="What is your question?"
          placeholder="Type your question..."
          value={questionText}
          onChange={(text) => {
            if (validationTriggered && !text) {
              setErrors((errors) => ({
                ...errors,
                questionText: "This is a required field and cannot be blank",
              }));
            }
            if (text) {
              setErrors((errors) => ({ ...errors, questionText: "" }));
            }
            setQuestionText(text);
          }}
          style={{ maxWidth: 550 }}
          hasError={!!errors.questionText}
          helperText={errors.questionText as string}
        />
      </QuestionCard>
      <QuestionCard>
        <IconContainer>
          <SvgSeries />
        </IconContainer>
        <H3 style={{ color: "#1B0076", marginBottom: 24 }}>Options</H3>
        {options.map((opt, idx) => (
          <FlexRow
            style={{
              marginBottom: 16,
              gap: 8,
              alignItems: "flex-end",
              maxWidth: 408,
            }}
            key={`${opt.id}-select-choice`}
          >
            <TextInput
              label={`Option ${idx + 1}`}
              value={opt.value}
              placeholder="Enter option"
              onChange={(text) => {
                if (validationTriggered && !text) {
                  setErrors((errors) => ({
                    ...errors,
                    options: {
                      ...errors.options,
                      [opt.id]: "Please enter an option (2 minimum)",
                    },
                  }));
                }
                if (text) {
                  setErrors((errors) => ({
                    ...errors,
                    options: {
                      ...errors.options,
                      [opt.id]: "",
                    },
                  }));
                }
                setOptions((options) => {
                  const newOptions = [...options];
                  newOptions[idx].value = text;
                  return newOptions;
                });
              }}
              hasError={!!errors.options[opt.id]}
              helperText={errors.options[opt.id]}
            />
            <RemoveContainer>
              {!!(idx > 1) && (
                <SvgCloseLarge
                  onClick={() =>
                    setOptions((opts) => opts.filter((o, i) => i !== idx))
                  }
                />
              )}
            </RemoveContainer>
          </FlexRow>
        ))}
        <Button
          variant="tertiary"
          leftIcon={<SvgAddAlt style={{ color: "#1B0076" }} />}
          onClick={() =>
            setOptions((options) => [
              ...options,
              { id: window.crypto.randomUUID(), value: "" },
            ])
          }
          style={{ width: 117, marginBottom: 20 }}
        >
          <LinkText style={{ color: "#1B0076" }}>Add Option</LinkText>
        </Button>
        {useDropdownModule ? (
          <Tooltip
            parent={
              <Selectable
                disabled={useDropdownModule}
                variant="checkbox"
                value={false}
                onChange={setAddOtherOption}
                label='Add "Other" Option. Allow your fans to enter their own response'
              />
            }
          >
            This is only available for fewer options (less than 8)
          </Tooltip>
        ) : (
          <Selectable
            disabled={useDropdownModule}
            variant="checkbox"
            value={addOtherOption}
            onChange={setAddOtherOption}
            label='Add "Other" Option. Allow your fans to enter their own response'
          />
        )}
      </QuestionCard>
      <FlexColumn style={{ marginBottom: 20 }}>
        <Td style={{ color: "#666" }}>
          How many options can your fans select?
        </Td>
        <CenterFlexRow>
          <Selectable
            variant="radio"
            value={selectOnlyOneOption}
            onChange={(selected) =>
              !!selected && setSelectOnlyOneOption(selected)
            }
            label="Select only one option"
            style={{ marginRight: 15 }}
          />
          <Selectable
            variant="radio"
            value={!selectOnlyOneOption}
            onChange={(selected) =>
              selected && setSelectOnlyOneOption(!selected)
            }
            label="Select more than one option"
          />
        </CenterFlexRow>
      </FlexColumn>
      <AddQuestionFooter
        required={required}
        setRequired={setRequired}
        confirmLabel={existing ? "Update" : "Add"}
        confirmDisabled={
          !questionText || !!options.find((opt) => !opt.value?.length)
        }
        onCancel={() => props.dispatch({ type: "closeModal" })}
        onConfirm={onAdd}
      />
    </>
  );
};

export const multipleChoice: QuestionTypeMeta = {
  icon: <SvgDropdownNav />,
  key: "multipleChoiceQuestion",
  header: "Multiple Choice",
  subHeader: "Give participants a selection of options to choose from",
  body: MultipleChoiceModalModal,
};

type ValidationErrors = {
  questionText: string;
  options: Record<string, string>;
};

type Option = {
  id: string;
  value: string;
  other?: boolean;
};

const IconContainer = styled.div`
  position: absolute;
  top: 25px;
  left: 26px;
`;

const RemoveContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  margin-bottom: 12px;
  color: #666;
  cursor: pointer;
  &:hover {
    color: #333;
  }
`;

const QuestionCard = styled(FlexColumn)`
  border-radius: 12px;
  background: #f2f5f7;
  padding: 25px 26px 25px 67px;
  position: relative;
  margin-bottom: 32px;
`;
