import { useState } from "react";
import { toast } from "react-hot-toast";
import { Button } from "melodies-source/Button";
import { ReactComponent as RefreshIcon } from "assets/svg/refresh.svg";
import { ReactComponent as DownloadIcon } from "assets/svg/download.svg";
import { ReactComponent as DocumentIcon } from "assets/svg/document2.svg";
import { CsvExportStatus } from "contexts/ArtistEventContext";
import { useArtistEventContext } from "contexts/ArtistEventContext";
import styled from "styled-components";
import { Spinner } from "melodies-source/Spinner";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useArtistContext } from "Components";
import { colors } from "melodies-source/Theme";

interface Request {
  artistGroupId: string;
  eventId: string;
  reportType: "entries" | "multiday";
}

interface DownloadCsvProps {
  isMultiDayEvent?: boolean;
}

export const DownloadCsv = ({
  isMultiDayEvent,
  ...props
}: DownloadCsvProps) => {
  const { event } = useArtistEventContext();
  const { artistGroupDoc, logAction } = useArtistContext();

  const [entriesReportStatus, setEntriesReportStatus] =
    useState<CsvExportStatus>("initial");
  const [entriesReportDownloadUrl, setEntriesReportDownloadUrl] =
    useState<string>(null);

  const [multidayReportStatus, setMultidayReportStatus] =
    useState<CsvExportStatus>("initial");
  const [multidayReportDownloadUrl, setMultidayReportDownloadUrl] =
    useState<string>(null);

  const callable = httpsCallable<Request, { downloadUrl: string }>(
    getFunctions(),
    "setlivev3-reporting-exportData",
  );

  const handleGenerateEntriesCsv = async () => {
    setEntriesReportStatus("generating");
    try {
      const { data } = await callable({
        artistGroupId: artistGroupDoc.id,
        eventId: event._id,
        reportType: "entries",
      });
      await logAction("portal_generate_entries", { event: event._id });
      toast.success("Your Entry Report is Ready for Download");
      setEntriesReportDownloadUrl(data.downloadUrl);
      setEntriesReportStatus("available");
    } catch (e) {
      console.error(e);
      setEntriesReportStatus("failed");
    }
  };

  const handleGenerateMultiDayStatsCsv = async () => {
    setMultidayReportStatus("generating");
    try {
      const { data } = await callable({
        artistGroupId: artistGroupDoc.id,
        eventId: event._id,
        reportType: "multiday",
      });
      await logAction("portal_generate_multiday", { event: event._id });
      toast.success("Your Stats By Day Report is Ready for Download");
      setMultidayReportDownloadUrl(data.downloadUrl);
      setMultidayReportStatus("available");
    } catch (e) {
      console.error(e);
      setMultidayReportStatus("failed");
    }
  };

  const handleDownloadEntriesReport = async () => {
    if (entriesReportDownloadUrl) {
      await logAction("portal_download_entries", { event: event._id });
      window.open(entriesReportDownloadUrl);
      setEntriesReportStatus("initial");
    }
  };

  const handleDownloadMultidayReport = async () => {
    if (multidayReportDownloadUrl) {
      await logAction("portal_download_multiday", { event: event._id });
      window.open(multidayReportDownloadUrl);
      setMultidayReportStatus("initial");
    }
  };

  const entriesButtonState =
    entriesReportStatus === "initial" ? (
      <OutlinedButton onClick={handleGenerateEntriesCsv}>
        <DocumentIcon />
        Generate Entry Report
      </OutlinedButton>
    ) : entriesReportStatus === "generating" ? (
      <OutlinedButton disabled>
        <Spinner color={colors.gray4} />
      </OutlinedButton>
    ) : entriesReportStatus === "failed" ? (
      <ErrorContainer>
        <OutlinedButton onClick={handleGenerateEntriesCsv}>
          <RefreshIcon />
          Restart Process
        </OutlinedButton>
        <ErrorMessage>There was an issue generating your report</ErrorMessage>
      </ErrorContainer>
    ) : entriesReportStatus === "available" ? (
      <StyledButton onClick={handleDownloadEntriesReport}>
        <DownloadIcon />
        Download Entry Report
      </StyledButton>
    ) : null;

  const multidayButtonState =
    multidayReportStatus === "initial" ? (
      <OutlinedButton onClick={handleGenerateMultiDayStatsCsv}>
        <DocumentIcon />
        Generate Stats By Day Report
      </OutlinedButton>
    ) : multidayReportStatus === "generating" ? (
      <OutlinedButton disabled>
        <Spinner color={colors.gray4} />
      </OutlinedButton>
    ) : multidayReportStatus === "failed" ? (
      <ErrorContainer>
        <OutlinedButton onClick={handleGenerateMultiDayStatsCsv}>
          <RefreshIcon />
          Restart Process
        </OutlinedButton>
        <ErrorMessage>There was an issue generating your report</ErrorMessage>
      </ErrorContainer>
    ) : multidayReportStatus === "available" ? (
      <StyledButton onClick={handleDownloadMultidayReport}>
        <DownloadIcon />
        Download Stats By Day Report
      </StyledButton>
    ) : null;

  return (
    <ButtonContainer {...props}>
      {isMultiDayEvent ? (
        <>
          {multidayButtonState}
          {entriesButtonState}
        </>
      ) : (
        entriesButtonState
      )}
    </ButtonContainer>
  );
};

const ErrorMessage = styled.span`
  font-size: 14px;
  max-width: 250px;
  text-align: center;
  margin-top: 5px;
  line-height: 1.2;
  color: #333333;
  @media all and (max-width: 1000px) {
    margin: 0 auto 15px;
    max-width: 100%;
  }
`;
const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > button {
    width: 300px;
    margin: 10px;
  }

  @media all and (max-width: 1000px) {
    width: 100%;

    & > button {
      width: 100%;
      font-size: min(4vw, 16px);
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > button {
    width: 300px;
    margin: 10px;
  }

  @media all and (max-width: 1000px) {
    flex-direction: column;
    width: 100%;

    & > button {
      width: 100%;
      font-size: min(4vw, 16px);
    }
  }
`;

const StyledButton = styled(Button)`
  display: flex;
  justify-content: space-evenly;
  padding: 10px 15px;

  &:disabled {
    background: #ababab !important;

    &:hover {
      cursor: unset;
      box-shadow: unset;
    }
  }
  @media all and (max-width: 1000px) {
    justify-content: center;
    gap: 15px;
  }
`;

const OutlinedButton = styled(Button)`
  display: flex;
  padding: 10px 15px;
  background-color: transparent;
  border: 1px solid var(--main-color);
  border-radius: 4px;
  color: var(--main-color);

  svg {
    min-width: 20px;
    margin-right: 8px;
  }
`;
