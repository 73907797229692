import styled from "styled-components";
import { truncateString } from "Utils";
import {
  CenterFlexRow,
  FlexColumn,
  Font16,
  Font26,
  Font30,
} from "Routes/styled";
import { useEventEntries, useTimeDiff } from "hooks";
import Calendar from "./Calendar";
import { DateTime } from "luxon";
import { SetLiveEvent } from "models/event";
import { useHistory } from "react-router-dom";
import { useArtistContext } from "Components";
import { formatNumber } from "Utils/format";

export const UpcomingShowCard = ({
  event,
  compact,
}: {
  event: SetLiveEvent;
  compact: boolean;
}) => {
  const { id: artistGroupId } = useArtistContext();
  const { diff: timeUntilEvent, time } = useTimeDiff({
    eventTime: event.startsAt,
  });

  const history = useHistory();

  const isLiveNow = event.startsAt < time.toMillis();

  const timeUntilValue = isLiveNow ? "Live" : timeUntilEvent.value;
  const timeUntilUnit = isLiveNow ? "Now" : timeUntilEvent.unit;

  const displayAddress = truncateString(event?.displayAddress || event.address);

  return (
    <InfoCardContainer
      onClick={() =>
        history.push(`/${artistGroupId}/reporting/set-live/shows/${event.id}`)
      }
    >
      <UpcomingShowBlendedBG />
      <InfoCardContent>
        <Calendar date={DateTime.fromMillis(event.startsAt)} />
        {!compact ? (
          <>
            <VenueText style={{ flex: 2 }}>
              {truncateString(event.venue)}
            </VenueText>
            <Font16 style={{ margin: "0 3%", maxWidth: "20%" }}>
              {displayAddress}
            </Font16>
          </>
        ) : (
          <FlexColumn>
            <VenueText
              onClick={() =>
                history.push(
                  `/${artistGroupId}/reporting/set-live/shows/${event.id}`,
                )
              }
            >
              {truncateString(event.venue)}
            </VenueText>
            <ShowLocationText>{displayAddress}</ShowLocationText>
          </FlexColumn>
        )}
        {!compact ? (
          <>
            <TimeUntilShowText style={{ marginRight: 5, marginLeft: "auto" }}>
              {timeUntilValue}
            </TimeUntilShowText>
            <TimeUntilShowUnit>{timeUntilUnit}</TimeUntilShowUnit>
          </>
        ) : (
          <MobileTimeUntil>
            <TimeUntilShowText>{timeUntilValue}</TimeUntilShowText>
            <TimeUntilShowUnit>{timeUntilUnit}</TimeUntilShowUnit>
          </MobileTimeUntil>
        )}
      </InfoCardContent>
    </InfoCardContainer>
  );
};

export const PastShowCard = ({ event }: { event: SetLiveEvent }) => {
  const { id: artistGroupId } = useArtistContext();
  const history = useHistory();

  const { diff: timeSinceEvent } = useTimeDiff({
    eventTime: event.endedAt,
  });
  const { entriesTotals } = useEventEntries(event.id, event.version);

  const displayAddress = truncateString(event?.displayAddress || event.address);

  return (
    <InfoCardContainer
      onClick={() =>
        history.push(`/${artistGroupId}/reporting/set-live/shows/${event.id}`)
      }
    >
      <PastShowBlendedBG />
      <InfoCardContent>
        <FlexColumn>
          <VenueText>{truncateString(event.venue)}</VenueText>
          <CenterFlexRow>
            <TimeSinceText as="div">
              {timeSinceEvent.value === 0
                ? "Live Now"
                : `${timeSinceEvent.value} ${timeSinceEvent.unit}`}
              &nbsp;•
            </TimeSinceText>
            <LocationText as="div">&nbsp;{displayAddress}</LocationText>
          </CenterFlexRow>
        </FlexColumn>
        <FlexColumn style={{ alignItems: "center", marginLeft: "auto" }}>
          <EntryCountText>
            {formatNumber(entriesTotals?.all ?? 0)}
          </EntryCountText>
          <LastResponseText>
            {(entriesTotals?.all ?? 0) !== 1 ? "Entries" : "Entry"}
          </LastResponseText>
        </FlexColumn>
      </InfoCardContent>
    </InfoCardContainer>
  );
};

const BlendedBG = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  mix-blend-mode: multiply;
  border-radius: 12px;
`;

const EntryCountText = styled(Font26)`
  font-weight: 700;
  line-height: 30px;
  text-align: center;

  ${({ theme }) => theme.media.tablet} {
    line-height: 25px;
  }

  ${({ theme }) => theme.media.mobile} {
    line-height: 20px;
  }
`;

const InfoCardContainer = styled.div`
  position: relative;
  height: 81px;
  display: flex;
  align-items: center;
  cursor: pointer;

  ${({ theme }) => theme.media.mobile} {
    height: 51px;
  }
`;

const InfoCardContent = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 20px 0 22px;
  width: 100%;
  height: 100%;

  ${Font16} {
    line-height: 22px;

    ${({ theme }) => theme.media.tablet} {
      font-size: 15px;
      line-height: 20px;
    }

    ${({ theme }) => theme.media.mobile} {
      font-size: 14px;
      line-height: 18px;
    }
  }
`;

const LastResponseText = styled(Font16)`
  ${({ theme }) => theme.media.tablet} {
    font-size: 12px;
    line-height: 18px;
  }
`;

const LocationText = styled(Font16)`
  ${({ theme }) => theme.media.tablet} {
    font-size: 12px;
    line-height: 24px;
  }
`;

const MobileTimeUntil = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
`;

const PastShowBlendedBG = styled(BlendedBG)`
  background: rgba(27, 0, 118, 0.2);
`;

const ShowLocationText = styled(Font16)``;

const TimeSinceText = styled(Font16)`
  ${({ theme }) => theme.media.tablet} {
    font-size: 15px;
    line-height: 22px;
  }
`;

const TimeUntilShowText = styled(Font30)`
  font-weight: 700;
  line-height: 25px;

  ${({ theme }) => theme.media.mobile} {
    line-height: 20px;
  }
`;

const TimeUntilShowUnit = styled(Font16)`
  line-height: 18px;
`;

const UpcomingShowBlendedBG = styled(BlendedBG)`
  background: rgba(27, 0, 118, 0.5);
`;

const VenueText = styled(Font16)`
  font-weight: 700;
`;
