import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { scrollBarDarkCss } from "melodies-source/common/layout";
import { usePopper } from "react-popper";
import { Placement } from "@popperjs/core/lib";

export const DropdownBackdrop = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  margin: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 99999999;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DropdownContainer = styled.div`
  background-color: white;
  border: solid 1px #f9fafb;
  position: absolute;
  border-radius: 4px;
  overflow-y: auto;
  ${scrollBarDarkCss};
  z-index: 1000000000000;
  box-shadow: 0 8px 20px 0 rgba(51, 72, 115, 0.13);
  border-radius: 0 0 6px 6px;
  &[data-popper-placement*="top"] {
    border-radius: 6px 6px 0 0;
  }
`;

const modifiers = [
  {
    name: "offset",
    options: {
      offset: ({ placement }: { placement: string }) => {
        if (placement.includes("top")) {
          return [0, 48];
        } else {
          return [0, 0];
        }
      },
    },
  },
  {
    name: "preventOverflow",
    options: {
      padding: 8,
    },
  },
];

type Props = {
  onClose?: any;
  isOpen?: boolean;
  placement?: Placement;
  children: any;
} & React.BaseHTMLAttributes<HTMLDivElement>;
export const Dropdown = ({
  onClose,
  isOpen = false,
  placement = "top",
  children,
  ...props
}: Props) => {
  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );
  const { styles, attributes, update } = usePopper(
    referenceElement,
    popperElement,
    {
      placement,
      modifiers,
    },
  );

  useEffect(() => {
    if (update) update();
  }, [isOpen]);

  return (
    <div ref={setReferenceElement}>
      {isOpen && (
        <>
          <DropdownBackdrop onClick={onClose}></DropdownBackdrop>
          <DropdownContainer
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
            {...props}
          >
            {children}
          </DropdownContainer>
        </>
      )}
    </div>
  );
};
