import { Redirect, Route, Switch } from "react-router-dom";
import { Dashboard } from "./Dashboard";
import { Fans } from "./Fans";
import { Settings } from "./Settings";
import STSReport from "./STSReport";
import { RouteComponentProps } from "react-router-dom";
import { Reporting } from "./Reporting";
import { SetLive } from "./SetLive";
import { ShowDetails } from "./SetLive/ShowDetails";
import { ArtistEventController } from "contexts/ArtistEventContext";
import { HelpCenter } from "./HelpCenter";
import { ArtistProvider } from "Components";
import { QrCodeModal } from "./Settings/Components/ArtistQrCode/QrCodeModal";
import { useDialog } from "Components/DialogProvider";
import { SetFan } from "./SetFan";
import { TypesenseProxyProvider } from "contexts/TypesenseProxyContext";

type Props = RouteComponentProps<{ artistId: string }>;

export const Routes = ({ match }: Props) => {
  const { dialog } = useDialog();

  return (
    <ArtistProvider>
      <TypesenseProxyProvider>
        {dialog?.qrCode && <QrCodeModal />}
        <Switch>
          <Route
            path={`${match?.path}/reporting/set-live/shows/:eventId`}
            render={(props) => (
              <ArtistEventController eventId={props.match.params.eventId}>
                <ShowDetails />
              </ArtistEventController>
            )}
          />
          <Route
            path={`${match?.path}/set-live/:tabParam`}
            component={SetLive}
          />
          <Route path={`${match?.path}/set-fan`} component={SetFan} />
          <Redirect
            exact
            from={`${match?.path}/set-live`}
            to={`${match?.path}/set-live/upcoming-shows`}
          />
          <Route path={`${match?.path}/help-center`} component={HelpCenter} />
          <Route
            path={`${match?.path}/fans`}
            render={(props) => <Fans match={props.match} />}
          />
          <Route
            path={`${match?.path}/reporting/:tabParam`}
            component={Reporting}
          />
          <Redirect
            exact
            from={`${match?.path}/reporting`}
            to={`${match?.path}/reporting/set-live`}
          />
          <Route path={`${match?.path}/settings`} component={Settings} />
          <Route path={`${match?.path}/sts-report`} component={STSReport} />
          <Route path={`${match?.path}/`} component={Dashboard} />
        </Switch>
      </TypesenseProxyProvider>
    </ArtistProvider>
  );
};
