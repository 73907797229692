import { ReactComponent as SvgLineStar } from "assets/svg/line-star.svg";
import { QuestionModalProps, QuestionTypeMeta } from "./BaseQuestionModal";
import styled from "styled-components";
import { FlexColumn } from "Components/Flex";
import { useState } from "react";
import { SvgHelp } from "melodies-source/Svgs/Help";
import { H3, Label } from "melodies-source/Text";
import { TextInput } from "Components";
import { ReactComponent as SvgSeries } from "assets/svg/series.svg";
import { Select } from "Components/StyledSelect";
import { AddQuestionFooter } from "./Footer";
import { RankedQuestionModule } from "@max/common/dist/setfan";

const RatingQuestionModal = ({
  existing,
  ...props
}: Omit<QuestionModalProps<RankedQuestionModule>, "state">) => {
  const [questionText, setQuestionText] = useState(existing?.label.en ?? "");
  const [ratingScale, setRatingScale] = useState<string>(
    existing?.rankRange?.toString() ?? undefined,
  );
  const [scaleLabels, setScaleLabels] = useState<string>(
    existing?.rankLow && existing?.rankHigh
      ? `${existing?.rankLow.en} / ${existing?.rankHigh.en}`
      : undefined,
  );
  const [required, setRequired] = useState(!!existing?.required);
  const [errors, setErrors] = useState({
    questionText: "",
    ratingScale: "",
    scaleLabels: "",
  });
  const [validationTriggered, setValidationTriggered] = useState(false);

  const onAdd = () => {
    setValidationTriggered(true);

    if (!questionText || !ratingScale || !scaleLabels) {
      setErrors({
        questionText: !questionText
          ? "This is a required field and cannot be blank"
          : "",
        ratingScale: !ratingScale
          ? "This is a required field and cannot be blank"
          : "",
        scaleLabels: !scaleLabels
          ? "This is a required field and cannot be blank"
          : "",
      });

      return;
    }

    const newModule = new RankedQuestionModule({
      id: existing?.id,
      displayOrder: "desc",
      label: { en: questionText },
      rankLow: { en: scaleLabels.split("/")[0].trim() },
      rankHigh: { en: scaleLabels.split("/")[1].trim() },
      rankRange: parseInt(ratingScale),
    });

    props.dispatch(
      existing
        ? {
            type: "updateModule",
            payload: { idToReplace: existing.id, module: newModule },
          }
        : {
            type: "addModule",
            payload: { module: newModule },
          },
    );

    props.dispatch({ type: "closeModal" });
  };

  return (
    <>
      <QuestionCard>
        <IconContainer>
          <SvgHelp style={{ color: "#1B0076", width: 29, height: 29 }} />
        </IconContainer>
        <H3 style={{ color: "#1B0076", marginBottom: 24 }}>Question</H3>
        <TextInput
          label="What is your question?"
          placeholder="Type your question..."
          value={questionText}
          onChange={(text) => {
            if (validationTriggered && !text) {
              setErrors((errors) => ({
                ...errors,
                questionText: "This is a required field and cannot be blank",
              }));
            }
            if (text) {
              setErrors((errors) => ({ ...errors, questionText: "" }));
            }
            setQuestionText(text);
          }}
          style={{ maxWidth: 550 }}
          hasError={!!errors.questionText}
          helperText={errors.questionText}
        />
      </QuestionCard>
      <QuestionCard>
        <IconContainer>
          <SvgSeries />
        </IconContainer>
        <H3 style={{ color: "#1B0076", marginBottom: 24 }}>Options</H3>
        {/* odd bug in dropdown items when using `label` prop */}
        <Label
          style={{
            marginBottom: 5,
            color: errors.ratingScale ? "red" : "black",
          }}
        >
          What is your rating scale?
        </Label>
        <Select
          options={[
            { value: "5", label: "5-Point Scale (1-5)" },
            { value: "10", label: "10-Point Scale (1-10)" },
          ]}
          onChange={(selection) => {
            if (validationTriggered) {
              setErrors((errors) => ({ ...errors, ratingScale: "" }));
            }
            setRatingScale(selection);
          }}
          value={ratingScale}
          style={{ marginBottom: 23 }}
          helperText={errors.ratingScale}
          hasError={!!errors.ratingScale}
        />
        <Label
          style={{
            marginBottom: 5,
            color: errors.scaleLabels ? "red" : "black",
          }}
        >
          Scale Labels
        </Label>
        <Select
          options={ScaleLabels.map((label) => ({
            value: label,
            label,
          }))}
          onChange={(selection) => {
            if (validationTriggered) {
              setErrors((errors) => ({ ...errors, scaleLabels: "" }));
            }
            setScaleLabels(selection);
          }}
          value={scaleLabels}
          helperText={errors.scaleLabels}
          hasError={!!errors.scaleLabels}
        />
      </QuestionCard>
      <AddQuestionFooter
        required={required}
        setRequired={setRequired}
        confirmLabel={existing ? "Update" : "Add"}
        confirmDisabled={!questionText || !ratingScale || !scaleLabels}
        onCancel={() => props.dispatch({ type: "closeModal" })}
        onConfirm={onAdd}
      />
    </>
  );
};

export const ratingQuestion: QuestionTypeMeta = {
  icon: <SvgLineStar />,
  key: "ratingQuestion",
  header: "Rating Question",
  subHeader: "Ask participants to rate something on your choice of scale",
  body: RatingQuestionModal,
};

const IconContainer = styled.div`
  position: absolute;
  top: 25px;
  left: 26px;
`;

const QuestionCard = styled(FlexColumn)`
  border-radius: 12px;
  background: #f2f5f7;
  padding: 25px 26px 25px 67px;
  position: relative;
  margin-bottom: 32px;
`;

const ScaleLabels = [
  "Hate It / Love It",
  "Strongly Disagree / Strongly Agree",
  "Never / All the Time",
  "Hard Pass / Definite Smash",
  "Meh / Mind-Blown",
  "Not Important / Very Important",
  "Not Effective / Very Effective",
  "Not Interested / Very Interested",
  "Poor Quality / Excellent Quality",
  "Would Not Recommend / Would Highly Recommend",
  "Not Enjoyable / Very Enjoyable",
  "Not Valuable At All / Highly Valuable",
  "Difficult / Easy",
  "Not Relevant / Highly Relevant",
  "Never Heard of it / Very Aware",
] as const;
